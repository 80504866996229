import React, { ReactElement, ReactNode } from 'react';
import { Globe, Mail, Map, MapPin, Phone, Printer } from 'react-feather';

export default function AddressCard({
    title,
    info,
    address,
    tel,
    fax,
    map,
    mapText,
    web,
    email,
}: {
    title: string;
    info?: string;
    address: string;
    tel: string;
    fax?: string;
    map?: string;
    mapText?: string;
    email?: string;
    web?: string;
}) {
    return (
        <div className="w-full lg:w-1/2 p-3 flex-1">
            <div className="p-3 shadow-lg flex items-center justify-center flex-col h-full hover:shadow-xl transform translate-y-0 hover:-translate-y-4 duration-200 transition-all border-t-4 border-stage-0">
                <div className="w-11 h-11 rounded-full bg-gray-300 text-primary flex items-center justify-center">
                    <MapPin />
                </div>
                <h3 className="font-semibold text-xl my-3">{title}</h3>
                {info && <p className="text-lg">{info}</p>}
                <Item content={address} />
                <Item icon={<Phone />} content={tel} href={`tel:${tel}`} />
                {fax && <Item icon={<Printer />} content={fax} />}
                {email && (
                    <Item
                        icon={<Mail />}
                        content={email}
                        href={`mailto:${email}`}
                    />
                )}
                {web && <Item icon={<Globe />} content={web} href={web} />}
                {map && <Item icon={<Map />} content={mapText} href={map} />}
            </div>
        </div>
    );
}

const Item = ({
    icon,
    content,
    href,
}: {
    icon?: ReactNode;
    content?: string;
    href?: string;
}) => {
    return (
        <div className="my-3 text-gray-700 flex max-w-xs">
            {icon && (
                <div className="w-8 h-8 bg-gray-100 rounded-full mr-3 flex items-center justify-center flex-shrink-0">
                    {React.cloneElement(icon as ReactElement, {
                        size: 16,
                    })}
                </div>
            )}
            <div className="flex-grow flex items-center text-center">
                {href ? (
                    <a className="text-gray-600" href={href} target="_blank">
                        {content}
                    </a>
                ) : (
                    <p className="text-gray-600">{content}</p>
                )}
            </div>
        </div>
    );
};
