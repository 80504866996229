import React, { useEffect, useState } from 'react';
import { AlertTriangle, CheckCircle } from 'react-feather';
import { useFormik } from 'formik';
import TextField from './ui/textfield';
import Button from './ui/button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';

export default function ContactForm() {
    const [state, setState] = useState<
        'loading' | 'error' | 'success' | 'neutral'
    >('neutral');

    const recaptcha = useGoogleReCaptcha();

    const intl = useIntl();
    const t = (s: string) => intl.formatMessage({ id: s });

    const validate = (values: any) => {
        const errors: Record<string, string | false> = {};

        if (!values.name) {
            errors.name = t('validationNoFullName');
        }

        if (!values.email) {
            errors.email = t('validationNoEmail');
        }

        if (!values.subject) {
            errors.subject = t('validationNoSubject');
        }

        if (!values.message) {
            errors.message = t('validationNoMessage');
        }

        if (!values['g-recaptcha-response']) {
            errors['g-recaptcha-response'] = t('validationNoRecaptcha');
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            'g-recaptcha-response': '',
        },
        onSubmit: (data) => {
            setState('loading');
            const formData = new FormData();

            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });

            fetch('https://getform.io/f/fe456bd8-12bc-4348-a7c5-178e1468d2b3', {
                method: 'POST',
                body: formData,
            })
                .then((data) => {
                    if (data.ok) {
                        setState('success');
                    } else {
                        setState('error');
                    }
                })
                .catch((err) => {
                    setState('error');
                });
        },
        validate,
    });

    useEffect(() => {
        if (recaptcha.executeRecaptcha) {
            formik.setFieldValue('g-recaptcha-response', '');
            recaptcha.executeRecaptcha().then((token) => {
                formik.setFieldValue('g-recaptcha-response', token);
            });
        }
    }, [recaptcha]);

    useEffect(() => {
        if (recaptcha.executeRecaptcha) {
            formik.setFieldValue('g-recaptcha-response', '');
            recaptcha.executeRecaptcha().then((token) => {
                formik.setFieldValue('g-recaptcha-response', token);
            });
        }

        let timeout: NodeJS.Timeout | null = null;
        if (state === 'success') {
            timeout = setTimeout(() => {
                setState('neutral');
                formik.resetForm();
            }, 5000);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [state]);

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <TextField
                label={t('name')}
                type="text"
                name="name"
                error={
                    formik.getFieldMeta('name').touched && formik.errors.name
                        ? (formik.errors.name as string)
                        : false
                }
                value={formik.values.name}
                onChange={formik.handleChange}
                textFieldProps={{
                    onBlur: formik.handleBlur,
                }}
            />
            <TextField
                label={t('email')}
                type="email"
                name="email"
                error={
                    formik.getFieldMeta('email').touched && formik.errors.email
                        ? (formik.errors.email as string)
                        : false
                }
                value={formik.values.email}
                onChange={formik.handleChange}
                textFieldProps={{
                    onBlur: formik.handleBlur,
                }}
            />
            <TextField
                label={t('phoneNumber')}
                type="text"
                name="phone"
                error={
                    formik.errors.phone
                        ? (formik.errors.phone as string)
                        : false
                }
                value={formik.values.phone}
                onChange={formik.handleChange}
                textFieldProps={{
                    onBlur: formik.handleBlur,
                }}
            />
            <TextField
                label={t('subject')}
                type="text"
                name="subject"
                error={
                    formik.getFieldMeta('subject').touched &&
                    formik.errors.subject
                        ? (formik.errors.subject as string)
                        : false
                }
                value={formik.values.subject}
                onChange={formik.handleChange}
                textareaProps={{
                    onBlur: formik.handleBlur,
                }}
            />
            <TextField
                label={t('message')}
                type="textarea"
                name="message"
                error={
                    formik.getFieldMeta('message').touched &&
                    formik.errors.message
                        ? (formik.errors.message as string)
                        : false
                }
                value={formik.values.message}
                onChange={formik.handleChange}
                textareaProps={{
                    onBlur: formik.handleBlur,
                }}
            />
            <div>
                <p
                    className="my-3"
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                            {
                                id: 'recaptchaPolicy',
                            },
                            undefined,
                            {
                                ignoreTag: false,
                            }
                        ),
                    }}
                ></p>
                {formik.errors['g-recaptcha-response'] && (
                    <div className="my-4 p-3 rounded bg-gray-100 text-red-800 flex items-center">
                        <AlertTriangle />
                        <p className="ml-4">
                            {formik.errors['g-recaptcha-response']}
                        </p>
                    </div>
                )}
                {(() => {
                    if (state === 'error') {
                        return (
                            <div className="my-4 p-3 rounded bg-red-600 text-white flex items-center">
                                <AlertTriangle />
                                <p className="ml-4">{t('formError')}</p>
                            </div>
                        );
                    }

                    if (state === 'success') {
                        return (
                            <div className="my-4 p-3 rounded bg-green-600 text-white flex items-center">
                                <CheckCircle />
                                <p className="ml-4">{t('formSuccess')}</p>
                            </div>
                        );
                    }
                })()}
                <Button
                    buttonProps={{
                        type: 'submit',
                        disabled: state === 'loading',
                    }}
                >
                    {state === 'loading' ? t('submitting') : t('submit')}
                </Button>
            </div>
        </form>
    );
}
