import React from 'react';
import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import Container from 'components/ui/container';
import ContactForm from 'components/contactform';
import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import AddressCard from 'components/addresscard';

export default function ProductsSingleTemplate({
    data,
}: {
    data: GatsbyTypes.ContactPageQuery;
}) {
    if (!data.contact) {
        return (
            <Layout seo={{ title: 'Not translated' }}>
                <div>This pages has not been translated yet.</div>
            </Layout>
        );
    }

    const intl = useIntl();

    const contactMainInfo = data.contact.frontmatter?.contactMainInfo?.map(
        (item) => {
            const props = {
                title: item?.title as string,
                address: item?.address as string,
                fax: item?.fax as string,
                info: item?.info as string,
                tel: item?.tel as string,
                map: item?.map as string,
                mapText: item?.mapText as string,
            };
            return <AddressCard {...props} key={props.title + props.tel} />;
        }
    );

    const distributorsInfo = data.contact.frontmatter?.contactDistributors?.map(
        (item) => {
            const props = {
                title: item?.title as string,
                address: item?.address as string,
                tel: item?.tel as string,
                // map: item?.map as string,
                // mapText: item?.mapText as string,
                email: item?.email as string,
                //web: item?.web as string,
            };
            return <AddressCard {...props} key={props.title + props.email} />;
        }
    );

    return (
        <Layout
            seo={{
                title: intl.formatMessage({
                    id: 'contactUs',
                }),
                description: data.contact?.frontmatter
                    ?.contactDescription as string,
                image: data.contact.frontmatter?.contactBanner
                    ?.publicURL as string,
            }}
        >
            <PageHeader
                title={intl.formatMessage({
                    id: 'contactUs',
                })}
                image={
                    (data.contact.frontmatter?.contactBanner?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
            />
            <Container>
                <div className="py-12 flex flex-wrap-reverse lg:flex-wrap">
                    <div className="w-full lg:w-1/2 p-3">
                        <GatsbyImage
                            image={
                                (data.contact.frontmatter?.contactImage
                                    ?.childImageSharp
                                    ?.gatsbyImageData as unknown) as IGatsbyImageData
                            }
                            alt=""
                        />
                    </div>
                    <div className="w-full lg:w-1/2 p-3">
                        <GoogleReCaptchaProvider
                            reCaptchaKey="6LfrErIaAAAAAOTmYAD5lk_wvOnqKkOBXuB7F_p5"
                            language={intl.locale}
                        >
                            <ContactForm />
                        </GoogleReCaptchaProvider>
                    </div>
                </div>
                <div className="block lg:flex flex-wrap items-stretch justify-center">
                    {contactMainInfo}
                </div>
                <div className="py-12">
                    <h2 className="font-black text-3xl text-gray-900 text-center pb-8">
                        {intl.formatMessage({
                            id: 'distributors',
                        })}
                    </h2>
                    <div className="block lg:flex flex-wrap items-stretch justify-center">
                        {distributorsInfo}
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

export const query = graphql`
    query ContactPage($locale: String!) {
        contact: mdx(
            fields: { sourceName: { eq: "options" }, locale: { eq: $locale } }
        ) {
            fields {
                locale
            }
            frontmatter {
                contactBanner {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                contactImage {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                contactDescription
                contactMainInfo {
                    title
                    info
                    address
                    tel
                    fax
                    map
                    mapText
                }
                contactDistributors {
                    title
                    address
                    tel
                    email
                    web
                    map
                    mapText
                }
            }
        }
    }
`;
